/* Styles for tab labels */
.mat-tab-label {
    background-color: transparent;
    background: linear-gradient(to right, #902d34 , #902d34);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    }

/* Styles for the active tab label */
.mat-tab-label-active {
    background-color: transparent;
    background: linear-gradient(to right,#902d34 , #902d34);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    }
/* Styles for the active tab label */
/* ink bar style */
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar{
    background-color: transparent;
    background: linear-gradient(to right, #902d34 , #dda9ae);
 } 
 