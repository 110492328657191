.ng-select {
  font-size: 15px !important;
  margin-top: -13px !important;
}

/* 
    go red when touched when touched
*/

// /* Border Color */
.ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: red;
}

// // /* Arrow Color */
.ng-select.ng-invalid.ng-touched .ng-arrow-wrapper .ng-arrow {
  border-color: red transparent transparent
}

// // /* Placeholder Color */
.ng-select.ng-invalid.ng-touched .ng-select-container .ng-placeholder {
  color: red;
}

