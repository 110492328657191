  .img-login-logo {
      width: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 4%;
  }

  .img-login-register {
    width: 20%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4%;
}

  .input-prefix-mat-icon {
      float: left;
      opacity: 0.5;
      margin-top: -5px;
  }

   .preview {
    max-width: 200px;
    vertical-align: middle;
  }

  #fileInput {
    position: absolute;
    cursor: pointer;
    z-index: 10;
    opacity: 0;
    height: 100%;
    left: 0px;
    top: 0px;
  }