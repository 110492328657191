/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import "~@ng-select/ng-select/themes/material.theme.css";
.fs-14-5 {
  font-size: 14.5px !important;
}

.mat-button-gradient {
  background-image: linear-gradient(to right, #b40019, #b04553);
  color: whitesmoke;
  margin-bottom: 2% !important;
}

.mat-button-gradient-green {
  background-image: linear-gradient(to right, #459332, #afb71a);
  color: whitesmoke;
  margin-bottom: 2% !important;
}

.mat-icon-delete {
  color: #EC7063;
  float: right;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

mat-accordion mat-expansion-panel {
  overflow: visible !important;
}

mat-card-title {
  font-size: 95% !important;
  font-weight: 950 !important;
  color: gray;
}

mat-sidenav-content {
  margin-top: 3%;
}

mat-panel-title {
  font-size: 95% !important;
  font-weight: 700 !important;
  color: gray !important;
}

mat-divider {
  margin-top: -0.5% !important;
}

mat-expansion-panel-header {
  background-color: white !important;
}

.mat-card-login {
  display: table-cell;
  vertical-align: middle;
  margin: 0 auto;
  margin-top: 10%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.min-height-500 {
  min-height: 350px !important;
  max-height: auto !important;
}

.truncate-cell {
  max-width: 400px;
  /* feel free to include any other value */
  white-space: wrap;
  overflow: auto;
  text-overflow: ellipsis;
}

mat-icon {
  cursor: pointer;
}

.mat-table {
  overflow: scroll !important;
  white-space: nowrap !important;
}

.mat-header-cell {
  color: #e25618 !important;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}
.table-responsive .mat-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  display: table;
  border-collapse: collapse;
  margin: 0px;
}
.table-responsive .mat-row,
.table-responsive .mat-header-row {
  display: table-row;
}
.table-responsive .mat-cell,
.table-responsive .mat-header-cell {
  word-wrap: initial;
  display: table-cell;
  padding: 0px 5px;
  line-break: unset;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}
.table-responsive mat-table mat-icon {
  color: #e25618 !important;
  zoom: 70%;
  padding-right: 20% !important;
}
.table-responsive .filter-class {
  zoom: 70%;
  margin: 0%;
}
.table-responsive .mat-row:nth-child(even) {
  background-color: #fffefe;
}
.table-responsive .mat-row:nth-child(odd) {
  background-color: #f1eaea;
}

form {
  zoom: 80%;
}

mat-form-field {
  margin-bottom: 0.5% !important;
}

.mat-form-field-required-marker {
  color: #F1948A;
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
  mat-autocomplete-panel {
    zoom: 80%;
    margin-left: -40% !important;
    margin-top: -15%;
    max-width: 80% !important;
    overflow-x: visible;
    overflow-y: auto;
  }
}
.img-login-logo {
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4%;
}

.img-login-register {
  width: 20%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4%;
}

.input-prefix-mat-icon {
  float: left;
  opacity: 0.5;
  margin-top: -5px;
}

.preview {
  max-width: 200px;
  vertical-align: middle;
}

#fileInput {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  opacity: 0;
  height: 100%;
  left: 0px;
  top: 0px;
}

/*------------------------------------------
  Responsive Grid Media Queries - 1280, 1024, 768, 480
   1280-1024   - desktop (default grid)
   1024-768    - tablet landscape
   768-480     - tablet 
   480-less    - phone landscape & smaller
--------------------------------------------*/
/*------------------------------------------
  Foundation Media Queries 
   http://foundation.zurb.com/docs/media-queries.html
--------------------------------------------*/
/* Small screens - MOBILE */
/* Define mobile styles - Mobile First */
/* max-width 640px, mobile-only styles, use when QAing mobile issues */
/* Medium screens - TABLET */
/* min-width 641px, medium screens */
/* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
/* Large screens - DESKTOP */
/* min-width 1025px, large screens */
/* min-width 1024px and max-width 1440px, use when QAing large screen-only issues */
/* XLarge screens */
/* min-width 1441px, xlarge screens */
/* min-width 1441px and max-width 1920px, use when QAing xlarge screen-only issues */
/* XXLarge screens */
/* min-width 1921px, xlarge screens */
/*------------------------------------------*/
/* Portrait */
@media screen and (orientation: portrait) {
  /* Portrait styles here */
}
/* Landscape */
@media screen and (orientation: landscape) {
  /* Landscape styles here */
}
/* CSS for iPhone, iPad, and Retina Displays */
/* Non-Retina */
/* Retina */
/* iPhone Portrait */
/* iPhone Landscape */
/* iPad Portrait */
/* iPad Landscape */
/* Styles for tab labels */
.mat-tab-label {
  background-color: transparent;
  background: linear-gradient(to right, #902d34, #902d34);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

/* Styles for the active tab label */
.mat-tab-label-active {
  background-color: transparent;
  background: linear-gradient(to right, #902d34, #902d34);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

/* Styles for the active tab label */
/* ink bar style */
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: transparent;
  background: linear-gradient(to right, #902d34, #dda9ae);
}

.ng-select {
  font-size: 15px !important;
  margin-top: -13px !important;
}

/* 
    go red when touched when touched
*/
.ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: red;
}

.ng-select.ng-invalid.ng-touched .ng-arrow-wrapper .ng-arrow {
  border-color: red transparent transparent;
}

.ng-select.ng-invalid.ng-touched .ng-select-container .ng-placeholder {
  color: red;
}

.float-right {
  float: right;
}

mat-error {
  font-size: 13px;
  margin-top: 1%;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #b60019;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #c36272;
}