/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import "~@ng-select/ng-select/themes/material.theme.css";
@import 'src/styles/font-styles.scss';
@import 'src/styles/buttons.scss';
@import 'src/styles/labels.scss';
@import 'src/styles/mat-card.scss';
@import 'src/styles/mat-table.scss';
@import 'src/styles/forms.scss';
@import 'src/styles/images.scss';
@import 'src/styles/media-query-layout.scss';
@import 'src/styles/mat-tab.scss';
@import 'src/styles/ng-select';




// Common SCSS
.float-right {
  float: right;
}

mat-error {
  font-size: 13px;
  margin-top: 1%;
}
 .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #b60019 ;
}

 .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #c36272 ;
}