form {
    zoom: 80%;
}

mat-form-field {
    margin-bottom: 0.5% !important;
}

.mat-form-field-required-marker {
    color: #F1948A;
}



// Handling the responsiveness
// .responsive {
//     background: yellow;
// @include devices(desktop) {
//     mat-autocomplete-panel {
//         zoom: 80%;
//         margin-left: -40% !important;
//         margin-top: -15%;
//         max-width: 80% !important;
//         overflow-x: visible;
//         overflow-y: auto;
//     }
// }

// @include devices(tablet) {
//     mat-autocomplete-panel {
//         zoom: 80%;
//         margin-left: -40% !important;
//         margin-top: -15%;
//         max-width: 80% !important;
//         overflow-x: visible;
//         overflow-y: auto;
//     }
// }
// }



@media all and (min-width: 1024px) and (max-width: 1280px) {
    mat-autocomplete-panel {
        zoom: 80%;
        margin-left: -40% !important;
        margin-top: -15%;
        max-width: 80% !important;
        overflow-x: visible;
        overflow-y: auto;
    }
  }