//  mat-tab {
//     overflow: visible !important;
// }
// mat-card{
//     overflow: visible !important;
// }

mat-accordion mat-expansion-panel {
    overflow: visible !important;
}

mat-card-title {
    font-size: 95% !important;
    font-weight: 950 !important;
    color: gray;
}

mat-sidenav-content {
    margin-top: 3%;
}

mat-panel-title {
    font-size: 95% !important;
    font-weight: 700 !important;
    color: gray !important;
}

mat-divider {
    margin-top: -.5% !important;
}


mat-expansion-panel-header {
    background-color: white !important;
}

.mat-card-login {
    display: table-cell;
    vertical-align: middle;
    margin: 0 auto;
    margin-top: 10%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.min-height-500 {
    min-height: 350px !important;               //card height(used in mat-card class)
    max-height: auto !important;               //card height(used in mat-card class)
    // overflow-y: scroll;
}

.truncate-cell {
    max-width: 400px;
    /* feel free to include any other value */
    white-space: wrap;
    overflow: auto;
    text-overflow: ellipsis;
}

mat-icon {
    cursor: pointer;
}