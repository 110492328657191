.mat-table {
  overflow: scroll !important;
  white-space: nowrap !important;
}

.mat-header-cell {
  color: #e25618 !important;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;

  .mat-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    display: table;
    border-collapse: collapse;
    margin: 0px;
  }

  .mat-row,
  .mat-header-row {
    display: table-row;
  }

  .mat-cell,
  .mat-header-cell {
    word-wrap: initial;
    display: table-cell;
    padding: 0px 5px;
    line-break: unset;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
  }

  mat-table mat-icon {
    color: #e25618 !important;
    zoom: 70%;
    padding-right: 20% !important;
  }

  .filter-class {
    zoom: 70%;
    margin: 0%;
  }

  .mat-row:nth-child(even) {
    background-color: rgb(255, 254, 254);
  }

  .mat-row:nth-child(odd) {
    background-color: rgb(241, 234, 234);
  }
}

 